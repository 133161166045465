// extracted by mini-css-extract-plugin
export var btnDisplay = "KeytopicsComponent-module--btnDisplay--fca55";
export var contactButton = "KeytopicsComponent-module--contactButton--821aa";
export var development = "KeytopicsComponent-module--development--8d307";
export var heading = "KeytopicsComponent-module--heading--fd7d6";
export var heroBackgroundCircleLeft = "KeytopicsComponent-module--heroBackgroundCircleLeft--996a5";
export var heroBackgroundInnerCircleLeft = "KeytopicsComponent-module--heroBackgroundInnerCircleLeft--1621f";
export var ourApproach = "KeytopicsComponent-module--ourApproach--e447a";
export var ourApproachNumber = "KeytopicsComponent-module--ourApproachNumber--d98ac";
export var yourBenefits = "KeytopicsComponent-module--yourBenefits--2b643";
export var yourBenefitsIcon = "KeytopicsComponent-module--yourBenefitsIcon--e1496";
export var yourBenefitsInner = "KeytopicsComponent-module--yourBenefitsInner--c3b22";
export var yourBenefitsItem = "KeytopicsComponent-module--yourBenefitsItem--782f8";