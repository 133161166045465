// extracted by mini-css-extract-plugin
export var backgroundCircles = "CaseStudiesMain-module--backgroundCircles--3cdfe";
export var card1 = "CaseStudiesMain-module--card1--226b1";
export var card2 = "CaseStudiesMain-module--card2--7adf6";
export var card3 = "CaseStudiesMain-module--card3--6260f";
export var caseStudiesButtonsContainer = "CaseStudiesMain-module--caseStudiesButtonsContainer--6ffa9";
export var caseStudiesDescription = "CaseStudiesMain-module--caseStudiesDescription--9bb24";
export var caseStudiesHeader = "CaseStudiesMain-module--caseStudiesHeader--7ec90";
export var caseStudiesMainContainer = "CaseStudiesMain-module--caseStudiesMainContainer--4cf9a";
export var caseStudiesSliderContainer = "CaseStudiesMain-module--caseStudiesSliderContainer--5c6ad";
export var caseStudiesTitleCircle = "CaseStudiesMain-module--caseStudiesTitleCircle--ab636";
export var heroBackgroundCircleLeft = "CaseStudiesMain-module--heroBackgroundCircleLeft--c0247";
export var sliderHrClassName = "CaseStudiesMain-module--sliderHrClassName--f9046";
export var sliderItem = "CaseStudiesMain-module--sliderItem--9b72c";