// extracted by mini-css-extract-plugin
export var awsMainImage = "SaasApplicationMain-module--awsMainImage--82242";
export var contactButton = "SaasApplicationMain-module--contactButton--0f0e4";
export var gdnPromotePic = "SaasApplicationMain-module--gdnPromotePic--d941e";
export var heroBackgroundCircleLeft = "SaasApplicationMain-module--heroBackgroundCircleLeft--5632c";
export var heroBackgroundInnerCircleLeft = "SaasApplicationMain-module--heroBackgroundInnerCircleLeft--0ef3a";
export var knowUs = "SaasApplicationMain-module--knowUs--2764d";
export var knowUsImage = "SaasApplicationMain-module--knowUsImage--351eb";
export var knowUsText = "SaasApplicationMain-module--knowUsText--1278c";
export var saasreview = "SaasApplicationMain-module--saasreview--c950b";
export var sassApplication = "SaasApplicationMain-module--sassApplication--699cb";