import React, { FC } from "react";
import * as styles from "./KeytopicsComponent.module.scss";
import InnerWrapper from "../InnerWrapper/InnerWrapper";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../CircleOutlineAndBlur/CircleBlur";

type Props = {
  KeytopicsData: any;
  keyTopicsTitle: any;
};

const KeyTopicsComponent: FC<Props> = ({ KeytopicsData, keyTopicsTitle }) => {
  return (
    <div className={styles.development}>
      <InnerWrapper>
        <div className={styles.yourBenefits}>
          <div className={styles.ourApproach}>
            <h2 className={styles.heading}>
              <span>.</span>
              {keyTopicsTitle === null ? "Deliverables" : keyTopicsTitle}
            </h2>
          </div>
          <div className={styles.yourBenefitsInner}>
            {KeytopicsData?.map((item: any, index: number) => {
              if (item.heading !== null) {
                return (
                  <div key={index} className={styles.yourBenefitsItem}>
                    <div className={styles.ourApproachNumber}>
                      {item?.count}
                    </div>
                    <h6 className="heading">
                      <span>.</span>
                      {item?.heading}
                    </h6>
                    <p>{item?.description}</p>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.8}
            animateOpacity={0.2}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          />
        </div>
      </InnerWrapper>
    </div>
  );
};

export default KeyTopicsComponent;
